import "swiper/css";

import cx from "classnames";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link, useI18next } from "gatsby-plugin-react-i18next";
import { getImage } from "libs/getImage";
import React, { useMemo } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

type Props = {
	activeId: string;
	characters: Queries.CharacterQuery["characters"];
};

export const CharacterSwiper = ({ characters, activeId }: Props) => {
	const { language } = useI18next();
	const initialSlide: number = useMemo(() => {
		const activeIndex = characters.edges.findIndex(
			(edge) => edge.node.id === activeId
		);

		return activeIndex === characters.edges.length - 1 ? 0 : activeIndex + 1;
	}, [characters, activeId]);

	return (
		<div className="grid grid-cols-5 lg:grid-cols-12">
			<Swiper
				centeredSlides
				loop
				loopedSlides={3}
				loopedSlidesLimit={false}
				initialSlide={initialSlide}
				slidesPerView="auto"
				className="col-span-full row-span-full max-w-[100vw] mt-12 mb-36 lg:mt-48 lg:mb-96"
			>
				{characters?.edges?.map(({ node }) => (
					<SwiperSlide key={node.id} className="w-full max-w-[417px]">
						<Link to={`/characters/${node.frontmatter?.slug}`} className="grid">
							<GatsbyImage
								image={getImage(node.frontmatter?.avatar)}
								alt="Thumbnail"
								className="col-span-full row-span-full -z-10"
							/>
							<div className="flex col-span-full row-span-full">
								<div className="w-2/3 pb-[6%] mt-auto text-right leading-tight">
									<div
										className={cx(
											"text-notice text-xl drop-shadow-[0_0_5px_black]",
											{ invisible: language === "en" }
										)}
									>
										{node.frontmatter?.armor?.title}
									</div>
									<div className="text-xs font-bold">
										{node.frontmatter?.armor?.en}
									</div>
									<h3 className="text-2xl font-bold">
										{node.frontmatter?.title}
									</h3>
								</div>
							</div>
						</Link>
					</SwiperSlide>
				))}
			</Swiper>
			<div className="z-10 col-span-1 col-start-1 row-span-full bg-gradient-to-r from-black to-transparent" />
			<div className="z-10 col-span-1 col-start-5 row-span-full bg-gradient-to-l from-black to-transparent lg:col-start-12" />
		</div>
	);
};
