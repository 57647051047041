import { CharacterPlayerButton } from "components/CharacterPlayerButton";
import { CharacterSwiper } from "components/CharacterSwiper";
import { Logo } from "components/Logo";
import { SectionHeading } from "components/SectionHeading";
import { graphql, PageProps } from "gatsby";
import {
	GatsbyImage,
	getImage,
	IGatsbyImageData,
	ImageDataLike,
	StaticImage
} from "gatsby-plugin-image";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { ReactComponent as DoubleArrowIcon } from "icons/double-arrow.svg";
import React, { useMemo } from "react";

export default function Characters({
	data,
}: PageProps<Queries.CharacterQuery>) {
	const { t } = useTranslation();
	const Flag = useMemo(
		() =>
			({
				cadette: <StaticImage src="../images/flag-cadette.png" alt="Cadette" />,
				mimic: <StaticImage src="../images/flag-mimic.png" alt="Mimic" />,
			}[data?.character?.frontmatter?.camps ?? ""]),
		[data?.character?.frontmatter?.camps]
	);

	return (
		<div className="grid">
			<StaticImage
				src="../images/character-bg.jpg"
				alt=""
				className="col-span-full row-span-full -z-10"
			/>
			<div className="pt-20 col-span-full row-span-full lg:pt-40">
				<Logo positioned />
				<div className="text-center">
					<SectionHeading as="h1">{t("nav.characters")}</SectionHeading>
				</div>
				<div className="w-full max-w-screen-[1508px] mx-auto lg:grid lg:grid-cols-6 lg:items-center">
					<div className="max-w-[100vw] overflow-x-hidden lg:row-span-full lg:col-start-3 lg:col-span-4">
						<div className="w-[591px] -ml-[calc(591px/2-50vw)] grid lg:w-full lg:max-w-screen-lg lg:ml-0">
							<GatsbyImage
								image={
									getImage(
										data.character?.frontmatter?.placeholder as ImageDataLike
									) as IGatsbyImageData
								}
								alt="Avatar"
								className="col-span-full row-span-full"
							/>
							<div className="flex items-end justify-center col-span-full row-span-full">
								<div
									className="
									w-full max-w-vw flex flex-col text-[42px] leading-none uppercase
									font-bold text-stroke text-stroke-pallet-violet text-fill-transparent
									p-6 lg:text-[84px] lg:max-w-none lg:pl-[25%]"
								>
									{data.character?.frontmatter?.armor?.en
										?.split(" ")
										.map((word, i) => (
											<span key={i}>{word}</span>
										))}
								</div>
							</div>
						</div>
					</div>
					<div className="z-0 px-2 lg:px-0 lg:row-span-full lg:col-start-2 lg:col-span-2">
						<section className="grid bg-violet-gradient lg:w-full lg:max-w-[460px]">
							<div className="col-span-full row-span-full -z-10">
								<div className="max-w-sm mx-auto lg:mr-0 lg:pt-20 lg:max-w-[300px]">
									{Flag}
								</div>
							</div>
							<div className="pt-10 col-span-full row-span-full lg:px-5">
								<div className="font-bold text-center lg:text-left mb-0.5 lg:mb-5">
									<p className="text-xl lg:text-3.5xl">
										{data.character?.frontmatter?.lines}
									</p>
									<h2 className="mt-4 text-3xl lg:mt-5 text-notice lg:text-2xl">
										{data.character?.frontmatter?.armor?.title}
									</h2>
									<h3 className="text-[56px] lg:text-5xl">
										{data.character?.frontmatter?.title}
									</h3>
								</div>
								<div className="flex flex-col items-center lg:items-start">
									<dl
										className="
												inline-grid grid-cols-[repeat(2,auto)] [&_dt]:text-notice
												gap-x-4 text-left lg:text-sm"
									>
										<dt>{t("meta.race")}</dt>
										<dd>{data?.character?.frontmatter?.meta?.race}</dd>
										<dt>{t("meta.height")}</dt>
										<dd>{data?.character?.frontmatter?.meta?.height}</dd>
										<dt>{t("meta.property")}</dt>
										<dd>{data?.character?.frontmatter?.meta?.property}</dd>
										<dt>{t("meta.skill")}</dt>
										<dd>{data?.character?.frontmatter?.meta?.skill}</dd>
									</dl>
									<div className="inline-grid grid-cols-2 gap-4">
										{[
											data?.character?.frontmatter?.hcg,
											data?.character?.frontmatter?.video,
										].map((video, i) => (
											<CharacterPlayerButton
												key={i}
												index={i + 1}
												source={video?.publicURL ?? ""}
												label={["HCG", t("meta.battle_video")][i]}
											/>
										))}
									</div>
								</div>
							</div>
							<p
								className="p-5 text-sm ls:text-lg lg:pb-16"
								dangerouslySetInnerHTML={{
									__html:
										data?.character?.frontmatter?.introduction?.replace(
											"\n",
											"<br>"
										) ?? "",
								}}
							/>
						</section>
					</div>
				</div>
				<div className="flex justify-center py-6 lg:py-20">
					<DoubleArrowIcon className="text-[56px] lg:text-[86px]" />
				</div>
				<div className="px-2 lg:px-0 md:max-w-screen-lg md:mx-auto">
					<section className="md:bg-violet-gradient md:relative md:py-6 md:px-12">
						<div className="grid p-8 justify-items-center md:contents">
							<GatsbyImage
								image={
									getImage(
										data?.character?.frontmatter?.weapon?.image as ImageDataLike
									) as IGatsbyImageData
								}
								alt="Weapon"
								className="col-span-full row-span-full md:!absolute md:-bottom-12 md:left-10"
							/>
							<div
								className="
								flex flex-col font-bold col-span-full row-span-full justify-self-start
								-z-10 text-[63px] lg:text-[84px] leading-none text-stroke
								text-stroke-pallet-violet text-fill-transparent md:absolute
								md:left-4 md:top-2"
							>
								{data?.decoration?.frontmatter?.weapon?.title
									?.split(" ")
									?.map((word, i) => (
										<span key={i}>{word}</span>
									))}
							</div>
						</div>
						<div className="px-5 pt-10 pb-20 bg-violet-gradient md:pt-0 md:pb-16 md:bg-none md:grow md:pl-[50%]">
							<h2 className="text-2xl font-bold text-center md:text-left text-notice">
								{t("exclusive_weapons")}
							</h2>
							<h3 className="mb-8 text-5xl font-bold text-center md:text-left lg:mb-10">
								{data?.character?.frontmatter?.weapon?.title}
							</h3>
							<p className="text-sm lg:text-xl">
								{data?.character?.frontmatter?.weapon?.introduction}
							</p>
						</div>
					</section>
				</div>
				<CharacterSwiper
					characters={data.characters}
					activeId={data.character?.id ?? ""}
				/>
			</div>
		</div>
	);
}

export const query = graphql`
	fragment Translation on Locale {
		ns
		data
		language
	}
	query Character($language: String!, $frontmatter__slug: String) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					...Translation
				}
			}
		}
		decoration: markdownRemark(
			frontmatter: { slug: { eq: $frontmatter__slug } }
			fields: { language: { eq: "en" } }
		) {
			frontmatter {
				weapon {
					title
				}
			}
		}
		characters: allMarkdownRemark(
			filter: {
				frontmatter: { type: { eq: "character" } }
				fields: { language: { eq: $language } }
			}
			sort: { fields: frontmatter___order, order: ASC }
		) {
			edges {
				node {
					...CharacterItem
				}
			}
		}
		character: markdownRemark(
			frontmatter: { slug: { eq: $frontmatter__slug } }
			fields: { language: { eq: $language } }
		) {
			id
			frontmatter {
				slug
				title
				lines
				introduction
				camps
				placeholder {
					childImageSharp {
						gatsbyImageData(
							sizes: "(max-width: 1024px) 100vw, 1018px"
							placeholder: BLURRED
						)
					}
				}
				armor {
					title
					en
				}
				weapon {
					title
					introduction
					# en
					image {
						childImageSharp {
							gatsbyImageData(
								width: 360
								placeholder: BLURRED
								layout: CONSTRAINED
							)
						}
					}
				}
				meta {
					height
					race
					property
					skill
				}
				hcg {
					publicURL
				}
				video {
					publicURL
				}
			}
		}
	}
`;

export { Head } from "components/GCSRewriteURL";
