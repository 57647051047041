import {
	getImage as gatsbyGetImage,
	IGatsbyImageData,
	ImageDataLike
} from "gatsby-plugin-image";

type TSource =
	| {
			readonly childImageSharp: {
				readonly gatsbyImageData: IGatsbyImageData;
			} | null;
	  }
	| null
	| undefined;

export const getImage = (source: TSource): IGatsbyImageData =>
	gatsbyGetImage(source as ImageDataLike) as IGatsbyImageData;
