import { ReactComponent as CameraIcon } from "icons/camera.svg";
import { ReactComponent as CloseIcon } from "icons/cross-o.svg";
import React, { Fragment, Suspense, useState } from "react";

import { Dialog, Transition } from "@headlessui/react";

const Player = React.lazy(() => import("react-player/file"));

type Props = {
	source: string;
	label: string;
	index: number;
};

export const CharacterPlayerButton = ({ index, label, source }: Props) => {
	const [isPlayerOpen, setIsPlayerOpen] = useState<boolean>(false);
	const onClose = () => setIsPlayerOpen(false);
	return (
		<Fragment>
			<button
				type="button"
				className="flex flex-col items-center py-4"
				onClick={() => setIsPlayerOpen(true)}
			>
				<span className="text-[45px] lg:text-[64px] lg:leading-none">{`0${index}`}</span>
				<span className="px-4 py-0.5 self-stretch text-center lg:text-xs bg-[#6E2389]">
					{label}
				</span>
				<CameraIcon className="text-[45px]" />
			</button>
			<Transition
				show={isPlayerOpen}
				enter="ease-out duration-300"
				enterFrom="opacity-0"
				enterTo="opacity-100"
				leave="ease-in duration-200"
				leaveFrom="opacity-100"
				leaveTo="opacity-0"
				as={Fragment}
			>
				<Dialog onClose={onClose}>
					<div className="fixed inset-0 z-20 bg-black/80" />
					<div className="fixed inset-0 z-30 overflow-y-auto">
						<div className="flex items-center justify-center min-h-full text-center p-9 lg:p-14">
							<div className="relative">
								<Dialog.Panel className="w-full max-w-[278px] landscape:max-w-[25vw] overflow-hidden align-middle aspect-character-video">
									<Suspense>
										<Player
											controls
											playing
											url={source}
											width="100%"
											height="100%"
										/>
									</Suspense>
								</Dialog.Panel>
								<button
									type="button"
									onClick={onClose}
									className="absolute top-0 right-0 transform translate-x-full -translate-y-full"
								>
									<CloseIcon className="text-3xl md:text-6xl" />
								</button>
							</div>
						</div>
					</div>
				</Dialog>
			</Transition>
		</Fragment>
	);
};
